import useModal from '../../../hook/use-modal';
import { PngAsset } from '../../../shared/asset/png';
import ModalContactUsComponent from '../modal/modal-contact-us';

type Props = Record<never, never>;

export default function FooterComponent(_: Props) {
  const modalContactUs = useModal();

  return (
    <footer id="contact" className="bg-black px-4 py-8 flex flex-col justify-center items-center">
      <button
        type="button"
        onClick={modalContactUs.open}
        className="bg-white text-black py-6 px-12 rounded-md"
      >
        <p className="font-bold text-2xl">CONTACT</p>
        <p className="text-xs mt-0.5 font-bold">お問い合わせ</p>
      </button>
      <div className="h-0.5 w-full mb-6 mt-8 bg-white" />
      <img src={PngAsset.logoWhite} alt="company-log" className="w-28 object-contain" />
      <p className="py-2 leading-normal whitespace-pre-wrap text-white tracking-widest text-sm">{`株式会社DonDot\n〒1600023\n東京都新宿区西新宿3丁目3番13号\n西新宿水間ビル2F`}</p>
      <div className="h-0.5 w-full my-6 bg-white" />
      <p className="text-xs text-white text-center">Copyright © 2023 DonDot Co.</p>
      {/* Modal */}
      {modalContactUs.render({
        children: <ModalContactUsComponent onClose={modalContactUs.close} />,
      })}
    </footer>
  );
}

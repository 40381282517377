type Props = {
  title: string;
  description: string;
  imageUrl?: string;
};

export default function ServiceItemComponent(props: Props) {
  const { title, description, imageUrl = '' } = props;

  return (
    <div className="rounded-md py-8 px-8 border border-black/30 flex flex-col justify-center">
      <p className="text-center text-sm">{title}</p>
      <img src={imageUrl} alt="service-logo" className="py-4 h-24 object-contain object-center" />
      <p className="mt-2 text-center leading-loose text-xs">{description}</p>
    </div>
  );
}

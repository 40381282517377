import { XCircleIcon } from '@heroicons/react/24/outline';
import { PngAsset } from '../../../shared/asset/png';
import AnchorLink from 'react-anchor-link-smooth-scroll';

type Props = {
  onClose: () => void;
};

export function ModalMenuComponent(props: Props) {
  const { onClose } = props;

  return (
    <nav className="bg-white w-full h-full flex justify-center items-center">
      <div className="justify-between flex py-2.5 px-6 fixed top-0 left-0 w-full">
        <img src={PngAsset.logoBlack} alt="company-log" className="w-24 object-contain" />
        <XCircleIcon className="h-10 w-10" onClick={onClose} />
      </div>
      <ul className="grid gap-6 text-center">
        <AnchorLink href="#top" onClick={onClose}>
          <MenuItemComponent title="TOP" />
        </AnchorLink>
        <AnchorLink href="#service" onClick={onClose}>
          <MenuItemComponent title="SERVICE" />
        </AnchorLink>
        <AnchorLink href="#company" onClick={onClose}>
          <MenuItemComponent title="COMPANY" />
        </AnchorLink>
        <AnchorLink href="#contact" onClick={onClose}>
          <MenuItemComponent title="CONTACT" />
        </AnchorLink>
      </ul>
    </nav>
  );
}

function MenuItemComponent(props: { title: string }) {
  return (
    <li>
      <p className="text-2xl">{props.title}</p>
    </li>
  );
}

import FooterComponent from '../component/layout/footer';
import CompanyInfoItemComponent from './company-info-item';
import { HeaderBarComponent } from '../component/layout/header';
import SectionTitleComponent from './section-title';
import ServiceItemComponent from './service-item';
import { SideNavBarBarComponent } from '../component/layout/side-nab-bar';
import { PngAsset } from '../../shared/asset/png';
import { SvgAsset } from '../../shared/asset/svg';

export default function IndexPage() {
  return (
    <div>
      <HeaderBarComponent className="flex md:hidden fixed top-0 left-0" />
      <SideNavBarBarComponent className="fixed h-screen left-0 hidden md:flex w-56" />
      <div className="overflow-y-scroll md:pl-56 pl-0">
        {/* TOP */}
        <section id="top" className="w-full px-6 md:px-16">
          <section className="pt-32 pb-16 text-center px-2 md:px-4 flex flex-col justify-center items-center">
            <h1 className="text-4xl font-bold">About us</h1>
            <p className="text-left text-sm mt-4 leading-relaxed tracking-wide md:mt-8 md:w-3/5 md:text-center">
              DonDot Inc.は東京のソフトウェア開発会社です。
              <br />
              誰もが日常的に利用するサービスを提供することで、世界に大きな価値を提供できることを目指します。
            </p>
          </section>

          {/* SERVICE */}
          <section id="service" className="py-12">
            <SectionTitleComponent title="Service" subTitle="事業内容" />
            <div className="gap-3 mt-4 grid md:grid-cols-3 md:gap-4">
              <ServiceItemComponent
                title="ソフトウェア受託開発"
                description="
                アプリやウェブサービスの新規開発や既存システムの追加機能開発など、企画・提案から開発・保守まで対応いたします。
              "
                imageUrl={SvgAsset.rocketLaunch}
              />
              <ServiceItemComponent
                title="訪問販売向け営業支援ツール"
                description={
                  `訪問販売向けの営業支援ツール\n` +
                  `iOS/Androidアプリと管理者用のWEBサイトを提供します。`
                }
                imageUrl={PngAsset.furoshikiLogo}
              />
              <ServiceItemComponent
                title="Shopify構築"
                description={`Shopify（ショッピファイ）の運用・制作`}
                imageUrl={SvgAsset.shopify}
              />
            </div>
          </section>

          {/* COMPANY */}
          <section id="company" className="py-12">
            <SectionTitleComponent title="Company" subTitle="会社概要" />
            <div className="flex flex-col gap-4 py-5 px-2 md:py-10 md:px-8">
              <CompanyInfoItemComponent title="会社" description="株式会社DonDot（ドンドット）" />
              <CompanyInfoItemComponent title="設立" description="2023年8月16日" />
              <CompanyInfoItemComponent
                title="所在地"
                description={`〒1600023\n東京都新宿区西新宿3丁目3番13号西新宿水間ビル2F`}
              />
              <CompanyInfoItemComponent title="代表取締役" description="山﨑 祐輝" />
              <CompanyInfoItemComponent title="資本金" description="1,000,000円" />
              <CompanyInfoItemComponent
                title="事業内容"
                description={`ソフトウェアサービス事業\nウェブサービス製作（受託開発）\nスマートフォンアプリ製作（受託開発）\n`}
              />
            </div>
          </section>
        </section>
        <FooterComponent />
      </div>
    </div>
  );
}

type Props = {
  title: string;
  subTitle: string;
};

export default function SectionTitleComponent(props: Props) {
  const { title, subTitle } = props;

  return (
    <div className="mt-4">
      <p className="text-xs font-bold tracking-widest">{subTitle}</p>
      <h1 className="mt-0.5 text-3xl font-bold">{title}</h1>
    </div>
  );
}

import { Bars3Icon } from '@heroicons/react/24/solid';
import { PngAsset } from '../../../shared/asset/png';
import useModal from '../../../hook/use-modal';
import { ModalMenuComponent } from './modal-menu';
import AnchorLink from 'react-anchor-link-smooth-scroll';

type Props = {
  className: string;
};

export function HeaderBarComponent(props: Props) {
  const { className } = props;

  const modalMenu = useModal();

  return (
    <nav className={`${className} w-full py-3 px-6 items-center justify-between bg-white`}>
      <AnchorLink href="#top">
        <img src={PngAsset.logoBlack} alt="company-log" className="w-36 object-contain" />
      </AnchorLink>
      <Bars3Icon className="h-8 w-8" onClick={modalMenu.open} />
      {modalMenu.render({
        children: <ModalMenuComponent onClose={modalMenu.close} />,
      })}
    </nav>
  );
}

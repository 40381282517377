type Props = {
  title: string;
  description: string;
};

export default function CompanyInfoItemComponent(props: Props) {
  const { title, description } = props;

  return (
    <div>
      <p className="font-bold text-lg border-b-2">{title}</p>
      <p className="mt-2 text-base leading-loose whitespace-pre-wrap">{description}</p>
    </div>
  );
}

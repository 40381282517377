import { ArrowDownLeftIcon } from '@heroicons/react/24/solid';
import { Constant } from '../../../shared/constant';

type Props = {
  onClose: () => void;
};

export default function ModalContactUsComponent(props: Props) {
  const { onClose } = props;

  return (
    <div className="h-full w-full bg-black/50">
      <div className="relative bg-stone-200 h-full flex justify-center items-center md:w-2/4 md:mx-auto">
        <div className="text-black">
          <p className="text-xs">下記のメールアドレスよりお問い合わせください</p>
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(Constant.companyEmailAddress);
            }}
            className="mt-2 font-bold tracking-widest text-2xl text-black hover:text-black/80 active:text-black/60"
          >
            {Constant.companyEmailAddress}
          </button>
          <div className="h-0.5 bg-black mt-1" />
          <p className="text-xs mt-2 text-black/50">
            ※ クリックするとクリップボードにコピーされます
          </p>
          <p className="mt-4 text-xs whitespace-pre-wrap leading-loose tracking-widest">{`〒1600023\n東京都新宿区西新宿3丁目3番13号\n西新宿水間ビル2F`}</p>
        </div>
        <ArrowDownLeftIcon
          className="text-black h-8 w-8 absolute bottom-4 left-4"
          onClick={onClose}
        />
      </div>
    </div>
  );
}

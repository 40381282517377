import { useEffect, useState } from 'react';

export type Props = {
  isOpen?: boolean;
};

export type RenderProps = {
  children: React.ReactElement;
  preventCloseOnBackdropClick?: boolean;
};

export type Returns = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  render: (renderProps: RenderProps) => React.ReactElement;
};

export default function useModal(props: Props = {}): Returns {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);

  const open = (): void => {
    setIsOpen(true);
  };

  const close = (): void => {
    setIsOpen(false);
  };

  // 背景のスクロールを制御するため
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  const render = (renderProps: RenderProps): React.ReactElement => {
    return (
      <div
        className={`inset-x-0 top-0 z-50 flex h-full flex-col items-center justify-center ${
          isOpen ? 'fixed' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="absolute -z-10 h-full w-full bg-black/60"
          onClick={renderProps.preventCloseOnBackdropClick ? () => {} : close}
        />
        {renderProps.children}
      </div>
    );
  };

  return {
    isOpen,
    open,
    close,
    render,
  };
}

import { PngAsset } from '../../../shared/asset/png';
import AnchorLink from 'react-anchor-link-smooth-scroll';

type Props = {
  className: string;
};

export function SideNavBarBarComponent(props: Props) {
  const { className } = props;

  return (
    <nav className={`${className} bg-white flex-col items-center py-24 border-r border-black/5`}>
      <img src={PngAsset.logoBlack} alt="company-log" className="w-40 object-contain text-center" />
      <ul className="grid gap-8 text-left mt-10">
        <AnchorLink href="#top">
          <MenuItemComponent title="TOP" />
        </AnchorLink>
        <AnchorLink href="#service">
          <MenuItemComponent title="SERVICE" />
        </AnchorLink>
        <AnchorLink href="#company">
          <MenuItemComponent title="COMPANY" />
        </AnchorLink>
        <AnchorLink href="#contact">
          <MenuItemComponent title="CONTACT" />
        </AnchorLink>
      </ul>
    </nav>
  );
}

function MenuItemComponent(props: { title: string }) {
  return (
    <li>
      <p className="text-base text-black/60 tracking-widest">{props.title}</p>
    </li>
  );
}
